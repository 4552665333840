/* If the device is mobile: */
#mobile {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: .5rem;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.grid-container {
  margin-top: 30px;
  margin-left: 0;
  margin-right: 30%;
  margin-bottom: 2%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  position: relative;
}

.grid-container > div {
  /* width: 200px; */
}

.box1 {
  border: 2px solid #D1D7DD;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #0028BD;
  color: #D1D7DD;
  font-weight: 700;
  letter-spacing: -.4px;
  font-size: 18px;
  border-right: none;
  width: 200px;
}

.box1 > span {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.box2 {
  border: 2px solid #D1D7DD;
  border-right: none;
  width: 150px;
  height: 100px;
}

.box4 {
  border: 2px solid #D1D7DD;
  background-color: #D7F24E;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 200px;
}

#logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  bottom: 40px;
  left: 10px;
}

#club {
  width: 150px;
  height: 100px;
}
/* End of Header */

/* affects all html */
body {
  width: 60%;
}
.Categories:active {
      background-color:#c4d7f7;
}

/* div that contains club rendering */
.Main {
  width: 50%;
  position: absolute;
  left: 50%;
  display: inline-block;
  transform: translate(-50%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
/* category + resources buttons */
.Categories {
  width: 60%;
  display: inline-block;
  height: 30px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 450;
  font-size: medium;
  color: #596069;
  background-color: white;
  outline: none;
  border-width: 1px;
  border-style: solid;
  /* border-radius: 10px; */
  margin-top: -2px;
  line-height: 1;
  letter-spacing: -.4px;
}

@media (max-width: 1400px) {
  .Categories {
    height: auto;
    padding: 6px;
    /* font-size: small; */
  }
}

@media (max-width: 640px) {
  .Categories {
    word-wrap: break-word;
    /* font-size: small; */
  }
}

/* category container div */
.Categories-bucket {
  width: 40%;
  padding-left: 10%;
  padding-right: 5%;
}
/* overlay */
#overlay {
  position: absolute;
  top: 0;
  left:0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
}

/* This styles the input form or search bar. */
input {
  margin: 10px;
  font-size: 18px;
  width: 710px;
  height: 43px;
  border-width: 0px;
  border-radius: 10px;
  border-color: #D1D7DD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  outline: none;
}

input[type=text] {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  box-sizing: border-box;
  color: #707070;
  border: 1px solid #ccc;
  letter-spacing: -.5px;
  font-weight: 600;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

/* Creates 2 columns */
.container {
  position: absolute;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

/* Global clubs css */
.clubs {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  border-radius: 10px;
  padding: 10px;
  max-width: 350px;
  margin: 10px;
  background-color: #FFFFFF; 
  display: inline-block; 
}

/* When user hovers mouse over club, changes the background color */
.clubs:hover {
  cursor: pointer;
  background-color: #F6F8FB;
  background-color: #F6F8FB;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 250ms linear;
}

.clubs[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.card-expand {
  font-weight: 450;
  font-size: medium;
  color: #596069; 
}

.card-expand-header {
  font-weight: bolder;
  font-size: xx-large;
  color: #4285F4;
  letter-spacing: -.5px;
}

.card-expand-leads {
  font-weight: bold;
  font-size: medium;
  margin-top: -1em;
  color: #596069;
  letter-spacing: -.5px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: #252424cc;
  height: 100%;
  width: 100vw;
}

dialog {
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  border: none;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 10px;
  width: 50%;
  background-color: #FFFFFF;
}

dialog::backdrop {
  background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.4));
  animation: fade-in 0.25s;
}

@keyframes fade-in {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}


/* styles the name of the club */
.card-header {
  font-weight: bolder;
  font-size: larger;
  color: #4285F4;
  letter-spacing: -.5px;
}

/* styles the names of the coleads. */
.card-leads {
  font-weight: bold;
  font-size: small;
  margin-top: -1em;
  color: #596069;
  letter-spacing: -.5px;
}

/* styles the club description */
.card-body {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 450;
  font-size: medium;
  color: #596069; 
  line-height: 1;
  margin-top: .5em;
  letter-spacing: -.4px;
  display: -webkit-box;
  overflow: hidden;
}

/* popup styling*/
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
  border: none;
  border-radius: 10px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 10px;
  width: 50%;
  margin:20px auto;
}

.modal_content {
  background-color: white;
  position: absolute;
  top: 20%;
  left: 30%;
  width: 40%;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid black;
}

.close {
  color: Black;
  float: right;
}

.close:hover {
  color: red;
  cursor: pointer;
}
 
/* applies to the button headers */
#title {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-weight: bolder;
  font-size: larger;
  letter-spacing: -.5px;
  padding-top: 10%;
  padding-left: 2%;
  color: #25292F;
}
